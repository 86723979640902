import React from 'react'
import Header from '../Controllers/Header'
import MERNDetails from '../Controllers/MERNDetails'
import Footer from '../Controllers/Footer'


const s = () => {
    return (
        <div>
            <Header/>
            <MERNDetails/>
            <Footer/>
        </div>
    )
}

export default s